import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Button } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ClassNameProps } from '@controls/types'
import { useLanguage } from '@hooks/useLanguage'
import { useQueryGetOffers } from '@hooks/ReactQuery/vehicles/useQueryGetOffers'
import { ScrollableDiv } from '@controls/ScrollableDiv/ScrollableDiv'
import { VehicleCard } from '@layout/VehicleCard'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer/ActionButtonsContainer'
import styles from './DiscountVehicles.module.css'

type Props = Readonly<{
  buttonsProps?: ClassNameProps
}> &
  ClassNameProps

export const DiscountVehicles: React.FC<Props> = ({ buttonsProps, className }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { routes } = useLanguage()

  const getOffers = useQueryGetOffers()

  const vehicles = getOffers.data ?? []

  return (
    <>
      <ScrollableDiv className={clsx(styles.scrollableDiv, className)}>
        {vehicles.map((vehicle) => (
          <VehicleCard key={vehicle.id} vehicle={vehicle} className={styles.vehicleCard} />
        ))}
      </ScrollableDiv>
      <ActionButtonsContainer className={buttonsProps?.className} justifyContentCenter>
        <Button variant="contained" endIcon={<ArrowForwardIcon />} onClick={() => navigate(routes.Catalog)}>
          {t('pages.main.sections.deals.button')}
        </Button>
      </ActionButtonsContainer>
    </>
  )
}
